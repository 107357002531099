import React, { useEffect, useRef, useState } from "react";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import "../Home/style.css";
import HeaderBox from "../Home/HeaderBox";
import SideBanner from "./SideBanner";
import Contact from "./Contact";
import Locators from "./Locators";
import { Helmet } from "react-helmet";

const ContactUs = ({
  navigationId,
  setNavigationId,
  setProductData,
  setParentProduct,
}) => {
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "rest-banner")
      : (windowSizeResolution.current.id = "rest-banner");
  }, []);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  const handleCallback = (childdata) => {
    setNavigationId(childdata);
  };
  const [AllowScroll, setAllowScroll] = useState(true);
  const handleWheel = (e) => {
    if (AllowScroll) {
      if (e.deltaY > 0) {
        if (navigationId < 2) {
          setNavigationId(navigationId + 1);
          setAllowScroll(false);
        }
      } else {
        if (navigationId > 1) {
          setNavigationId(navigationId - 1);
          setAllowScroll(false);
        }
      }
    }
  };
  useEffect(() => {
    setTimeout(() => setAllowScroll(true), 1000);
  }, [navigationId]);
  const downHandler = ({ key }) => {
    if (AllowScroll) {
      if (key === "ArrowDown") {
        if (navigationId < 2) {
          setNavigationId(navigationId + 1);
          setAllowScroll(false);
        }
      }
      if (key === "ArrowUp") {
        if (navigationId > 1) {
          setNavigationId(navigationId - 1);
          setAllowScroll(false);
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", downHandler);
  });
  return (
    <>
      <div
        className="rest-banner"
        ref={windowSizeResolution}
        // onWheel={(e) => handleWheel(e)}
      >
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className="container-fluid">
          <div className="row min_height">
            <div className="col-12 col-lg-3 d-flex align-items-end">
              <SideBanner
                parentCallback={handleCallback}
                navigationId={navigationId}
              />
            </div>
            <div className="col-12 col-lg-9 contact-us-title ">
              {/* {navId === 0 && <Banner />} */}
              <Contact />
              {/* {navigationId === 2 && <Locators />} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
