import React, { useEffect, useRef } from "react";
import Header from "../../../Home/Header";
import Navbar from "../../../commons/Navbar";
import "../../../Home/style.css";
import HeaderBox from "../../../Home/HeaderBox";
import styles from "./CareersStyle.module.css";
import img1 from "./infra-1.jpg";
const CustomDesignToolsHolder = ({
  navigationId,
  setNavigationId,
  setProductData,
  setParentProduct,
}) => {
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "rest-banner")
      : (windowSizeResolution.current.id = "rest-banner");
  }, []);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const [navId, setNavId] = useState(1);
  // const handleCallback = (childdata) => {
  //   setNavigationId(childdata);
  // };
  // console.log(Arr[0].megaMenu[0].subMenu);
  return (
    <>
      <div className="rest-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3"></div>
            <div className={`${styles.customTitleh1} col-12 col-lg-9 `}>
              <h1>Tool Holder</h1>
              <div className={styles.DictionaryContainer}>
                <div className="row">
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH1_c4MkbHRVB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255291881" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH2_t1CrIwpDJDcR.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255291995" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH3_mfMn03Gxh.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255292294" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH4_RMcvDx6cl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255292777" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH6_UVSzpSHq4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255293544" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH7_du8o2QyYG.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255294013" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH8_Y3YJiyoMYHKT.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255294496" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH9_nH3VCcCr5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255294578" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TH5_nCo8MC9xB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255293109" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/th1_bsXTpMlBe.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647603885940" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/th2_m2H1iLrjk-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647604069949" />
                    <h5></h5>
                    <p></p>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomDesignToolsHolder;
