import React, { useState } from "react";
import styles from "./ContactStyle.module.css";
import emailjs from "@emailjs/browser";
import axios from "axios";
import Socialmedia from "../SocialMedia/Socialmedia";

function Contact() {
  const [EmailValue, setEmailValue] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [CompanyNameValue, setCompanyNameValue] = useState("");
  const [MobileValue, setMobileValue] = useState("");
  const [MessageValue, setMessageValue] = useState("");
  const [CityValue, setCityValue] = useState("");

  const handleSubmit = () => {
    var payload = {
      // service_id: "service_s06nym8",
      // template_id: "template_6tk4x9g",
      // user_id: "user_znuNUI2JayN2YmMJCVqkd",
      service_id: "service_6y2mx6c",
      template_id: "template_dt6fpdp",
      user_id: "Za3wa01HeoeXmQqsY",
      template_params: {
        html_message: `
          customer email: ${EmailValue},
          customer name: ${NameValue},
          company name: ${CompanyNameValue},
          mobile number: ${MobileValue},
          City: ${CityValue},
          Message: ${MessageValue}
        `,
        mail_title: `
          Info from contact us form
        `,
      },
    };
    axios({
      method: "post",
      url: "https://api.emailjs.com/api/v1.0/email/send",
      data: JSON.stringify(payload), // you are sending body instead
      headers: {
        // 'Authorization': `bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    alert("Form Sent Successfully");
  };
  const handleEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const handleName = (e) => {
    setNameValue(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyNameValue(e.target.value);
  };
  const handleMobile = (e) => {
    setMobileValue(e.target.value);
  };
  const handleMessage = (e) => {
    setMessageValue(e.target.value);
  };
  const handleCountry = (e) => {
    setMessageValue(e.target.value);
  };
  const handleCity = (e) => {
    setCityValue(e.target.value);
  };
  return (
    <>
      <h1>Contact Us</h1>
      <div className={styles.DictionaryContainer}>
        <div className={styles.CareersContent}>
          <div className={styles.CareersForm}>
            <form onSubmit={handleSubmit}>
              <div>
                <label>NAME*</label>
                <input
                  placeholder="Your Name*"
                  onChange={handleName}
                  required
                />
              </div>
              <div>
                <label>MOBILE*</label>
                <input
                  placeholder="Mobile No*"
                  onChange={handleMobile}
                  required
                />
              </div>
              <div>
                <label>EMAIL*</label>
                <input placeholder="Email*" onChange={handleEmail} required />
              </div>
              <div>
                <label>COMPANY NAME*</label>
                <input
                  placeholder="Company Name"
                  onChange={handleCompanyName}
                  required
                />
              </div>
              <div>
                <label>CITY*</label>
                <input placeholder="Your City" onChange={handleCity} required />
              </div>
              <div>
                <label>COUNTRY*</label>
                <input
                  placeholder="Your Country"
                  onChange={handleCountry}
                  required
                />
              </div>
              <div>
                <label>MESSAGE</label>
                <input placeholder="Your Message" onChange={handleMessage} />
              </div>

              <div>
                <button type="submit">SUBMIT</button>
              </div>
            </form>
          </div>
          <div className={styles.CareersInfo}>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1029.6059841961246!2d77.50852093431067!3d13.018176268691612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3221fbd4c31dcea0!2zMTPCsDAxJzA1LjMiTiA3N8KwMzAnMzEuMyJF!5e0!3m2!1sen!2sin!4v1652417150824!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1029.6059841961246!2d77.50852093431067!3d13.018176268691612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3221fbd4c31dcea0!2zMTPCsDAxJzA1LjMiTiA3N8KwMzAnMzEuMyJF!5e0!3m2!1sen!2sin!4v1652417150824!5m2!1sen!2sin"
              // width="500"
              // height="250"
              // style="border:0;"
              // allowfullscreen=""
              // loading="lazy"
            ></iframe>
            <div className={styles.CarreAddress}>
              <div className={styles.AddressIndia}>
                <h2>Corporate Office :</h2>
                <h6 style={{ fontWeight: "bold" }}>
                  Sphoorti Machine Tools Pvt. Ltd.
                </h6>
                <h6>
                  # 18, Plot No. 467-469, 12th Cross, <br /> 4<sup> th</sup>
                  Phase, Peenya Industrial Area, <br />
                  Bengaluru - 560 058. INDIA
                  <br /> Ph: + 91 80 4127 2768 / 4117 1589 <br />
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; + 91 80 4117 1589 */}
                  {/* <br /> */}
                  Email : info@sphoorti.com
                </h6>
                {/* <h4>Follow us on</h4>
                <Socialmedia /> */}
              </div>
              <div className={styles.AddressGermany}>
                <h2>Germany Sales Outlet :</h2>
                <h6 style={{ fontWeight: "bold" }}>
                  Micromatic Machine Tools Pvt. Ltd.
                </h6>
                <h6>
                  Fuessner Str. 14-18 Pfronten <br /> Weissbach – 87459 <br />{" "}
                  Germany
                  <br /> Ph: +49 1722 3166 88
                  <br />
                  Email : debjoyr@acemicromatic.com
                </h6>
                {/* <h4>Follow us on</h4>
                <Socialmedia /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
