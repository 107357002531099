import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SeoData from "./seo-list.json";
import { useHistory } from "react-router-dom";
const SEO = () => {
  let { pathname, href: canonicalLink } = window.location;

  const [customSEODATA, setcustomSEODATA] = useState([]);

  const history = useHistory();
  useEffect(() => {
    history.listen((location) => {
      let getSeoItem = SeoData?.filter(
        (item) => item?.pathname == location?.pathname
      );
      // console.log(getSeoItem.length);
      if (getSeoItem?.length > 0) {
        setcustomSEODATA(getSeoItem);
      } else {
        setcustomSEODATA([
          {
            title: "Top machine tool manufacturers in India Sphoorti ",
            description:
              "Sphoorti is a well-established machine tool manufacturer, managed and powered by a team of experienced engineers and professionals. We are one of the top machine tool manufacturers in India",
            keywords:
              "sphoorti machine tools, best machine tools, vdi tool holder, machine tool manufacturing, ",
          },
        ]);
        console.log("not found");
      }
    });
  }, [history?.location?.pathname]);
  // console.log("customSEODATA");
  // console.log(customSEODATA, "cccciiooo");
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{customSEODATA?.[0]?.title}</title>
        <meta name="description" content={customSEODATA?.[0]?.description} />
        <meta name="keywords" content={customSEODATA?.[0]?.keywords} />
        {/* 
        {customSEODATA.length > 0 && (
          <>
            <title>{customSEODATA[0].title}</title>
            <meta name="description" content={customSEODATA[0].description} />
            <meta name="keywords" content={customSEODATA[0].keywords} />
          </>
        )} */}

        <link rel="canonical" href={canonicalLink} />
      </Helmet>
    </div>
  );
};

export default SEO;
