import React from "react";
import logo from "./assets/logo.png";
import logo_1 from "./assets/top-left1.png";
import borderIcon from "./assets/white-diam.png";
import HomeSlider from "./HeaderBoxSliders/HomeSlider";
import HeaderSlidersItem from "./HeaderBoxSliders";

export default function HeaderBox(id) {
  let imgtag = true;
  let classNames = "";
  let classNamesNumber = id.id;
  let mainLink = window.location.href;
  console.log(mainLink);
  console.log(mainLink == "/");
  if (classNamesNumber === 0) {
    classNames = "header-box-main-container";
  }
  // if (mainLink === "http://localhost:3000/infrastructure") {
  //   imgtag = false;
  // }
  // if (classNamesNumber === 1) {
  //   classNames = "header-box-main-container header-box-main-container-company";
  // }
  // if (classNamesNumber === 2) {
  //   classNames = "header-box-main-container header-box-main-container-product";
  // }
  // if (classNamesNumber === 3) {
  //   classNames = "header-box-main-container header-box-main-container-infra";
  // }
  // if (classNamesNumber === 4) {
  //   classNames =
  //     "header-box-main-container header-box-main-container-news-event";
  // }
  // if (classNamesNumber === 5) {
  //   classNames = "header-box-main-container header-box-main-container-catalog";
  // }

  return (
    <>
      <div className="header-box-main-container">
        <div id="home-page-border-left"></div>
        <div id="home-page-border-top"></div>
        {/* <img src={borderIcon} className="home-page-icon-2" /> */}
        {/* <img src={borderIcon} className="home-page-icon-1" /> */}
        <div className="header-box">
          <a href="https://www.sphoorti.com/">
          <img src={logo} href="" />
          </a>
        </div>

        <div id="home-page-border"></div>
        {/* <img src={imgtag} className="header-box-bottom-img" /> */}
        {/* {imgtag ? <img src={logo_1} className="header-box-bottom-img" /> : ""} */}
        {imgtag ? <img src={logo_1} className="header-box-bottom-img" /> : ""}
      </div>
      {/* <HeaderSlidersItem id={id} /> */}
    </>
  );
}
