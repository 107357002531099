import React, { useState } from "react";
import styles from "./Resources.module.css";
import Blogs from "../assets/Blogs.json";
import { Button, Form, Modal } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
function Blog() {
  const [show, setShow] = useState(false);
  const [Content, setContent] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (element) => {
    setContent(element);
    setShow(true);
  };
  return (
    <>
      {handleShow && (
        <div className={styles.DictionaryMainContainer}>
          <h1 style={{ textAlign: "center", marginTop: "2%" }}>Blogs</h1>
          <div className={styles.DictionaryContainer}>
            {Blogs.map((element, i) => {
              return (
                <div className={styles.RowContainer} key={i}>
                  <h1>{element.Heading}</h1>
                  <p>{element.exert}</p>
                  <Link to={`${element.link}/${element.id}`}>
                    <Button
                      style={{
                        backgroundColor: "#0e6777",
                        marginRight: "10px",
                      }}
                      // onClick={() => handleShow(element)}
                    >
                      Read More
                    </Button>
                  </Link>
                  <span>{element.date}</span>
                  <hr />
                </div>
              );
            })}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>SPHOORTI BLOG</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h1>{show && Content.Heading}</h1>
                {show &&
                  Content.content.paragraph.map((para) => {
                    return <p>{para}</p>;
                  })}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}

export default Blog;
