import React, { useRef, useState } from "react";
import product_img from "./assets/tool2.png";
import product1 from "./assets/catologue/bmt-tool-holders.jpg";
import pdf1 from "./assets/catologue/bmt-tool-holders.pdf";
import product2 from "./assets/catologue/driven-tool-holder.jpg";
import pdf2 from "./assets/catologue/driven-tool-holder.pdf";
import product3 from "./assets/catologue/reduction-sleeves.jpg";
import pdf3 from "./assets/catologue/reduction-sleeves.pdf";
import product4 from "./assets/catologue/slotted-tool-disc.jpg";
import pdf4 from "./assets/catologue/slotted-tool-disc.pdf";
import product5 from "./assets/catologue/vdi-td-th-13.jpg";
import pdf5 from "./assets/catologue/vdi-td-th-13.pdf";
import product6 from "./assets/catologue/vtp-tool-holder.jpg";
import pdf6 from "./assets/catologue/vtp-tool-holder.pdf";
import Slider from "react-slick";
import { Button, Form, Modal } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { useEffect } from "react";

export default function Catologue() {
  const CatogueContent = [
    {
      id: 1,
      title: `SLOTTED TOOL DISCS & TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/slotted-tool-disc_1gXLabjAN.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703468416`,
      imgName: "product_img.png",
      pdf: `${pdf4}`,
      pdfLink:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Home/docs/Slotted_Tool_Discs_Jan_2023_compressed_HbgzHrB28.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1673859158271",
    },
    {
      id: 2,
      title: `VDI TOOL DISCS & TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/vdi-td-th-13_uHphSHcgo.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703468579`,
      imgName: "product_img.png",
      pdf: `${pdf5}`,
      pdfLink:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Home/docs/VDI_TD___TH_Jan_2023_compressed_unKobpSWG.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1673859170245",
    },
    {
      id: 5,
      title: `DRIVEN TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/driven-tool-holder_lCEqKZFvw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703467261`,
      imgName: "product_img.png",
      pdf: `${pdf2}`,
      pdfLink:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Home/docs/Driven_Tool_Holders_Jan_2023_compressed_CuTC2pqK8.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1673859034942",
    },
    {
      id: 3,
      title: `BMT TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/bmt-tool-holders_ip4kYWH_V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703467081`,
      imgName: "product_img.png",
      pdf: `${pdf1}`,
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/3_BMT_Tool_Holders_Final_Dec_2021_9XXgJPgOy.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652172485197",
    },
    {
      id: 4,
      title: `VTP TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/vtp-tool-holder_PbmifuPTh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703468828`,
      imgName: "product_img.png",
      pdf: `${pdf6}`,
      pdfLink:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Home/docs/VTP_Tool_Holders_Jan_2023_compressed_ok5iAyKOw.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1673859078144",
    },

    {
      id: 6,
      title: `REDUCTION SLEEVES`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/reduction-sleeves_TW7sEJF44XHW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703467550`,
      imgName: "product_img.png",
      pdf: `${pdf3}`,
      pdfLink:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Home/docs/Reduction_Sleeves_Jan_2023_compressed_JFU70f6EA.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1673859160184",
    },
  ];
  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [show, setShow] = useState({ show: false, pdfId: "1" });
  const [EmailValue, setEmailValue] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [CompanyNameValue, setCompanyNameValue] = useState("");
  const [MobileValue, setMobileValue] = useState("");
  const [dataCollected, setDataCollected] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (localStorage.getItem("dataCollected")) {
      setDataCollected(true);
    }
  }, []);

  const pdfload = (id) => {
    const pdfItem = CatogueContent.filter((item) => item.id === parseInt(id));
    setDataCollected(true);
    localStorage.setItem("dataCollected", true);
    window.open(`${pdfItem[0].pdfLink}`);
  };
  console.log(show);
  const handleShow = (id) => {
    if (dataCollected) {
      setShow((prevValues) => {
        return {
          // show: true,
          pdfId: id,
        };
      });
      pdfload(id);
    } else {
      setShow((prevValues) => {
        return {
          show: true,
          pdfId: id,
        };
      });
    }
    // pdfId = id;
  };
  const handleSubmit = () => {
    // sessionStorage.setItem("item_key", "item_value");
    // var item_value = sessionStorage.getItem("item_key");
    setShow((prevValues) => {
      return {
        ...prevValues,
        show: false,
      };
    });
    var payload = {
      service_id: "service_6y2mx6c",
      template_id: "template_ym3e0z5",
      user_id: "Za3wa01HeoeXmQqsY",
      template_params: {
        html_message: `
          customer email: ${EmailValue},
          customer name: ${NameValue},
          company name: ${CompanyNameValue},
          mobile number: ${MobileValue}
        `,
        mail_title: `
          Customer info from catalogue
        `,
      },
    };
    axios({
      method: "post",
      url: "https://api.emailjs.com/api/v1.0/email/send",
      data: JSON.stringify(payload), // you are sending body instead
      headers: {
        // 'Authorization': `bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // window.open(
    //   "https://ik.imagekit.io/syeovulacjk/sptools/asset/docs/pdf/2_IVcDPVtHpLV.pdf"

    // );
    pdfload(show.pdfId);
  };

  const handleEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const handleName = (e) => {
    setNameValue(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyNameValue(e.target.value);
  };
  const handleMobile = (e) => {
    setMobileValue(e.target.value);
  };
  return (
    <>
      <div className="catologue-main-container">
        <h3 className="text-center m-3">Catalogues </h3>
        <Slider {...settings}>
          {CatogueContent.map((value, index) => (
            // <a
            //   href="https://ik.imagekit.io/syeovulacjk/sptools/asset/docs/pdf/2_IVcDPVtHpLV.pdf"
            //   target="_blank"
            // >
            <div
              className="catologue-container"
              key={value.id}
              onClick={() => handleShow(value.id)}
            >
              <img
                src={value.imageURL}
                // src={product_img}
                alt="Avatar"
                className="catologue-image"
              />
              <h2>{value.title}</h2>
            </div>
            // </a>
          ))}
        </Slider>
      </div>
      <Modal show={show.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Catologues Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                onChange={(e) => handleName(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => handleEmail(e)}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company Name"
                onChange={(e) => handleCompanyName(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Mobile Number"
                onChange={(e) => handleMobile(e)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={
              NameValue.length < 3 ||
              EmailValue.length < 5 ||
              CompanyNameValue.length < 3 ||
              MobileValue.length < 10
            }
          >
            Download Catalogue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
