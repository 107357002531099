import React from "react";
import Slider from "react-slick";
import styles from "./Events.module.css";
import { Button, Form, Modal } from "react-bootstrap";
import image from "./engimach.png";
import emco from "./events/sphoorti_2.jpg";

import newsEvents from "./newssevents.json";
export default function NewsInfrastructure() {
  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const news = [
    {
      id: 1,
      heading: `Tool Discs0`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 3,
      heading: `Tool Discs1`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 3,
      heading: `Tool Discs2`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 4,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 5,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
  ];
  const events = [
    {
      id: 1,
      heading: `Tool Discs0`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 3,
      heading: `Tool Discs1`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 3,
      heading: `Tool Discs2`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 4,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 5,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
  ];

  return (
    <>
      {/* <div className="news-events-main"></div> */}
      <div className={styles.DictionaryMainContainer}>
        <h1>Events</h1>
        {/* <div className={styles.DictionaryContainer}>
          <div className={styles.RowContainer}>
            <h3>ENGIMACH 2021</h3>

            <img src={image} />
            <p style={{ marginTop: "20px", textAlign: "center" }}>
              Sphoorti will be participating in ENGIMACH 2021, which is held
              from 1st December To 5th December at Helipad Exhibition Centre,
              Gandhinagar, Gujarat, in Hall 2, Stall 83A.
            </p>
          </div>
          <div className={styles.RowContainer}>
            <h3>intec 2019</h3>

            <img src={image} />
            <p>
              Sphoorti will be participating in intec 2019 which is to be held
              from 6th June to 10th June 2019 at CODISSIA Trade Fair Complex,
              Coimbatore, India in Hall – E, STALL NO -9 & 10 .
            </p>
          </div>
          <div className={styles.RowContainer}>
            <h3>RMTS 2018</h3>

            <p>
              Sphoorti will be participating in RMTS 2018, which is held from
              28th Nov To 1st Dec 2018 in Rajkot.
            </p>
          </div>
          <div className={styles.RowContainer}>
            <h3>SUBCON 2018</h3>

            <img src={image} />
            <p>
              From 5th – 7th June 2018 our company will be participating in
              SUBCON 2018 which is to be held in Birmingham.
            </p>
          </div>
        </div> */}
        <div
          className={`${styles.DictionaryContainer} ${styles.NewsEventsContainer}`}
        >
          <div className="container">
            {newsEvents.map((item, index) => {
              if (index % 2 == 0) {
                return (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                        <img src={item.img} />
                      </div>
                      <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2 event_title">
                        <h3>{item.title}</h3>
                        <h3>{item.stall}</h3>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              } else {
                return (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 event_title">
                        <h3>{item.title}</h3>
                        <h3>{item.stall}</h3>
                      </div>
                      <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                        <img src={item.img} />
                      </div>
                    </div>
                    <hr />
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}
