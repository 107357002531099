import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Home/Header";
import Navbar from "../../../commons/Navbar";
import "../../../Home/style.css";
import HeaderBox from "../../../Home/HeaderBox";
import styles from "../../ProductStyle.module.css";
import Arr from "../../../assets/product.json";

const DrivenToolHolders = ({ navigationId, setNavigationId }) => {
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "rest-banner")
      : (windowSizeResolution.current.id = "rest-banner");
  }, []);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const [navId, setNavId] = useState(1);
  // const handleCallback = (childdata) => {
  //   setNavigationId(childdata);
  // };
  // console.log(Arr[1].megaMenu[0].subMenu[29].subsubmenu);
  return (
    <>
      <div className="rest-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar setNavigationId={setNavigationId} />
        <div className="container-fluid">
          <div className={`row min_height ${styles.ContentContainer}`}>
            <div>
              <h2>Driven Tool Holders</h2>
              <h5>
                VDI driven tool holders are manufactured in accordance to DIN
                standards &amp; made out of toughened steel (C-45) as well as
                case hardened steel (20MnCr5). Shanks are induction hardened
                &amp; teeth are ground as per DIN 69880. Drive couplings are
                available as per DIN 5482, DIN5480, DIN 1809, TOEM &amp; others
                like Okuma, HAAS, Mazak, EMAG, Hyundai WIA &amp; many more.
                These holders are equipped with precision bearings, seals &amp;
                high quality gears. Driven holders are also available in various
                ratios of output &amp; input speed as well as offset.
              </h5>
            </div>
            <div className={styles.DictionaryContainer}>
              {alphabetArray.map((alpha, i) => {
                return (
                  <>
                    {Arr[1].megaMenu[0].subMenu[29].subsubmenu.some((element) =>
                      element.title.startsWith(alpha.toUpperCase())
                    ) && (
                      <div className={styles.AlphaContainer} key={i}>
                        <b>{alpha.toUpperCase()}</b>
                        <ul>
                          {Arr[1].megaMenu[0].subMenu[29].subsubmenu.map(
                            (element, index) => {
                              return (
                                element.title.startsWith(
                                  alpha.toUpperCase()
                                ) && (
                                  <li key={index}>
                                    {
                                      <a
                                        // onClick={() => handleSelectManu(element)}
                                        href={`https://www.sphoorti.com${element.link}`}
                                      >
                                        {element.title}
                                      </a>
                                    }
                                  </li>
                                )
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
        {/* <SideBanner />
        <About /> */}
        {/* <div className="container border-1">
          <div className="row ">
            <div className="col-md-3 border-1 d-flex align-items-end ">
              <SideBanner parentCallback={handleCallback} />
            </div>
            <div className="col-md-8 " style={{ marginTop: "2%" }}>
              {navId === 0 && <Banner />}
              {navId === 1 && <About />}
              {navId === 2 && <Products />}
              {navId === 3 && <Infrastructure />}
              {navId === 4 && <NewsAndEvents />}
              {navId === 5 && <Catalogue />}
            </div>
          </div>
        </div> */}
        {/* <div className="container ">
          <div className="row">
            <div className="col-md-10 " style={{ marginTop: "2%" }}>
              {navId === 0 && <Banner />}
              {navId === 1 && <About />}
              {navId === 2 && <Products />}
              {navId === 3 && <Infrastructure />}
              {navId === 4 && <NewsAndEvents />}
              {navId === 5 && <Catalogue />}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <SideBanner parentCallback={handleCallback} />
            </div>
          </div>
        </div> */}
      </div>

      {/* <br /> */}
    </>
  );
};

export default DrivenToolHolders;
