import React, { useEffect, useRef } from "react";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import "../Home/style.css";
import HeaderBox from "../Home/HeaderBox";
import styles from "./CareersStyle.module.css";
import logo from "./sphoorti-logos.png";
const Careers = ({
  navigationId,
  setNavigationId,
  setProductData,
  setParentProduct,
}) => {
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "rest-banner")
      : (windowSizeResolution.current.id = "rest-banner");
  }, []);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const [navId, setNavId] = useState(1);
  // const handleCallback = (childdata) => {
  //   setNavigationId(childdata);
  // };
  // console.log(Arr[0].megaMenu[0].subMenu);

  return (
    <>
      <div className="product-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className={styles.DictionaryMainContainer}>
          <h1>Careers</h1>
          <div className=" ">
            <div className={styles.DictionaryContainer}>
              <div className={styles.CareersContent}>
                <p className={styles.CareersInfo}>
                  Join <br /> <img src={logo} /> <br /> Start a new career!
                  Become a part of our innovative and dynamic family and explore
                  different paths of growth!.
                </p>
                <div className={styles.CareersForm}>
                  <form>
                    <div>
                      <label>NAME*</label>
                      <input placeholder="Your Name*" />
                    </div>
                    <div>
                      <label>CONTACT NUMBER*</label>
                      <input placeholder="Contact No*" />
                    </div>
                    <div>
                      <label>EMAIL*</label>
                      <input placeholder="Email*" />
                    </div>
                    <div>
                      <label>
                        UPLOAD RESUME*<span>(max. size 1MB)</span>
                      </label>
                      <input type="file" />
                    </div>
                    {/* <div>
                      <label>COVER LETTER</label>
                      <input placeholder="Provide the brief about the position you are applying for" />
                    </div> */}
                    <div>
                      <button style={{ color: "white" }}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
