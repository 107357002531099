import React, { useEffect, useState, useRef } from "react";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import "../Home/style.css";
import HeaderBox from "../Home/HeaderBox";
import styles from "./ProductStyle.module.css";
import { Table } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
// import Arr from "../../assets/product.json";
import { FaHandPointRight } from "react-icons/fa";
const ProductPage = ({
  navigationId,
  setNavigationId,
  setProductData,
  setParentProduct,
  ProductData,
}) => {
  let history = useHistory();
  const parentData = JSON.parse(localStorage.getItem("ParentProduct"));
  const parentProduct = JSON.parse(localStorage.getItem("ParentProduct"));
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  const [Loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz0123456789".split("");
  const BASE_IMG_URL =
    "https://ik.imagekit.io/syeovulacjk/sptools/asset/images/product/";
  // const [navId, setNavId] = useState(1);
  // const handleCallback = (childdata) => {
  //   setNavigationId(childdata);
  // };
  useEffect(() => {
    const urlSteps = parentData.map((element) =>
      (element.title.match(/\d/) !== null
        ? element.title.slice(2)
        : element.title
      )
        .replace(/\s+/g, "-")
        .toLowerCase()
    );
    const stringURL = urlSteps.reduce(
      (prevValue, element) => `${prevValue}/${element}`
    );
    history.push(`/product-data/${stringURL}`);
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/${JSON.parse(
          localStorage.getItem("FinalProductCode")
        )}`
      )
      .then((data) => {
        setData(JSON.parse(data.data.data.productValues));
        console.log(data.data.data);
        console.log(JSON.parse(data.data.data.productValues));
        setLoading(false);
      });
  }, []);

  function replaceCommaLine(data) {
    //convert string to array and remove whitespace
    let dataToArray = data.split(",").map((item) => item.trim());
    return dataToArray.map((element) => (
      <>
        {dataToArray.length > 1 ? `${element},` : `${element}`}
        <br />
      </>
    ));
  }

  const handleSelectProduct = (prop, pg = 0) => {
    let temp1 = JSON.parse(localStorage.getItem("ParentProduct"));
    let _inx = temp1.find((d) => {
      if (d.code == prop[0].code) return d;
    });
    //temp1[0] = {};
    if (_inx == null) {
      temp1.push(prop[0]);
      localStorage.setItem("ParentProduct", JSON.stringify(temp1));
    } else {
      let temp2 = [];
      let _flag = 0;
      temp1.forEach((ele) => {
        if (ele.code == prop[0].code) {
          if (_flag == 0) temp2.push(ele);
          _flag = 1;
        } else {
          if (_flag == 0) temp2.push(ele);
        }
      });
      localStorage.setItem("ParentProduct", JSON.stringify(temp2));
    }
    if (prop[0].hasChildNode === true) {
      setParentProduct(prop);
      //localStorage.setItem("ParentProduct", JSON.stringify(prop));
      localStorage.setItem("ParentProductCode", JSON.stringify(prop[0].code));
      // history.push(`/${prop[0].parent}`);
      window.location.reload();
    } else {
      if (pg == 1) {
        setParentProduct(prop);
        localStorage.setItem("ParentProduct", JSON.stringify(prop));
        localStorage.setItem("ParentProductCode", JSON.stringify(prop[0].code));
        // history.push(`/${prop[0].parent}`);
        window.location.reload();
      } else {
        setProductData(prop);
        localStorage.setItem("FinalProductCode", JSON.stringify(prop[0].code));
        history.push("/product-data");
      }
    }
  };
  return (
    <>
      <div className="product-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className="container-fluid" id={styles.productMobileContainer}>
          <div className={`${styles.ContentContainer}`}>
            <div className={`${styles.ContentContainerDesc}`}>
              {/* <button onClick={() => history.goBack()}>Back</button> */}
              <h1>{parentProduct[0].title}</h1>
              {/* <h5>
                {parentProduct[0].code}
              </h5> */}

              {
                <h5>
                  <FaHandPointRight />
                  {parentData.map((element, index) => {
                    return (
                      <>
                        <span
                          class="breadcrubms"
                          onClick={() =>
                            handleSelectProduct(
                              [
                                {
                                  title: element.title,
                                  description: element.description,
                                  code: element.code,
                                  hasChildNode: element.hasChildNode,
                                },
                              ],
                              1
                            )
                          }
                        >
                          {element.title.match(/\d/) !== null
                            ? element.title.slice(2)
                            : element.title}
                        </span>
                      </>
                    );
                  })}
                </h5>
              }
            </div>
            <div className={styles.ProductDataContainer}>
              {!Loading && (
                <h1
                  className="about-content-title"
                  style={{ textAlign: "center" }}
                >
                  {Data[0].table1[0].title}
                </h1>
              )}
              {!Loading && (
                <h1
                  className="about-content-title"
                  style={{ textAlign: "center" }}
                >
                  {Data[0].table1[0].subtitle}
                </h1>
              )}
              {/* <p>{ProductData.content}</p> */}
              {!Loading && (
                <img
                  alt={Data[0].table1[0].title}
                  src={`${BASE_IMG_URL}${Data[0].table1[0].image_code}`}
                />
              )}

              {Loading ? (
                <h6></h6>
              ) : (
                <h3 style={{ textAlign: "left" }}>
                  {Data[0].table1[0].tableTitle}
                </h3>
              )}

              {Loading ? (
                <h6>Loading...</h6>
              ) : (
                <table style={{ marginTop: "3%" }}>
                  <thead className="product-table-column">
                    <tr>
                      {Data[0].table1[0].identifier.map((element) => {
                        return (
                          <th style={{ width: `${element.width}%` }}>
                            {element.bigFont}
                            {element.smallFont && (
                              <b style={{ fontSize: "10px", width: "30px" }}>
                                {element.smallFont}
                              </b>
                            )}
                          </th>
                        );
                      })}
                      {/* <th rowSpan={2}>Code</th>
                  <th rowSpan={2}>
                    ØA<br></br>VDI
                  </th>
                  <th rowSpan={2}>ØB</th>
                  <th rowSpan={2}>C</th>
                  <th rowSpan={2}>ØD</th>
                  <th rowSpan={2}>~ØE</th>
                  <th rowSpan={2}>ØF</th>
                  <th rowSpan={2}>ØG</th>
                  <th rowSpan={2}>Y</th>
                  <th colSpan={4}>TURRET TYPE</th>
                  <th colSpan={2}>
                    Online Catalog */}
                      {/* <tr> */}
                      {/* <th style={{ borderRight: "2px solid" }}>Front(Left)</th>
                    <th>Rear(Right)</th> */}
                      {/* </tr> */}
                      {/* </th> */}
                    </tr>
                    {/* <tr>
                  <th>PRAGATI BTP</th>
                  <th>Duplomatic-BSV-N</th>
                  <th>Baruffaldi – TOE</th>
                  <th>Sauter 05-480</th>
                  <th>Front(Left)</th>
                  <th>Rear(Right)</th>
                </tr> */}
                  </thead>
                  {/* <tbody>
                {ProductData.table.map((row) => {
                  return (
                    //         "ØA(VDI)": "16",
                    // "ØB": "160",
                    // "C": "34",
                    // "ØD": "200",
                    // "~ØE": "230",
                    // "ØF": "25",
                    // "ØG": "70",
                    // "Y": "-",
                    // "TURRET TYPE": [
                    //   {
                    //     "PRAGATI BTP": "50",
                    //     "Duplomatic-BSV-N": "100",
                    //     "Baruffaldi – TOE": "100",
                    //     "Sauter 05-480": "510"
                    //   }
                    // ],
                    // "Online Catalog": [
                    //   {
                    //     "Front (Left)": "/VDI-Tool-Discs–Static-Tools-8-Station/TD-VDI-S-50-8-16-160"
                    //   },
                    //   {
                    //     "Rear (Right)": "/VDI-Tool-Discs–Static-Tools-8-Station/TD-VDI-S-50-8-16-160"
                    //   }
                    <tr>
                      <td>{row.CODE}</td>
                      <td>{row.ØA}</td>
                      <td>{row.ØB}</td>
                      <td>{row.C}</td>
                      <td>{row.ØD}</td>
                      <td>{row.ØE}</td>
                      <td>{row.ØF}</td>
                      <td>{row.ØG}</td>
                      <td>{row.Y}</td>
                      <td>{row.TURRET_TYPE[0].PRAGATI_BTP}</td>
                      <td>{row.TURRET_TYPE[0].Duplomatic_BSV_N}</td>
                      <td>{row.TURRET_TYPE[0].Baruffaldi_TOE}</td>
                      <td>{row.TURRET_TYPE[0].Sauter_05_480}</td>
                      <td>
                        {row.Online_Catalog[0].Front && (
                          <div>
                            <img
                              alt="pdf"
                              src={process.env.PUBLIC_URL + "/pdf-icon.png"}
                            />
                            <img
                              alt="pdf"
                              src={process.env.PUBLIC_URL + "/pdf-icon.png"}
                            />
                            <img
                              alt="dxf"
                              src={process.env.PUBLIC_URL + "/dxf-icon.png"}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        {row.Online_Catalog[1].Rear && (
                          <div>
                            <img
                              alt="pdf"
                              src={process.env.PUBLIC_URL + "/pdf-icon.png"}
                            />
                            <img
                              alt="pdf"
                              src={process.env.PUBLIC_URL + "/pdf-icon.png"}
                            />
                            <img
                              alt="dxf"
                              src={process.env.PUBLIC_URL + "/dxf-icon.png"}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody> */}
                  <tbody>
                    {Data[0].table1[0].value.map((element) => {
                      return (
                        <tr>
                          {element.map((el) => {
                            return (
                              <td>
                                {typeof el === "object" ? (
                                  `${el.pdf}, ${el.stp}, ${el.dxf}`
                                ) : (
                                  <span>
                                    <br />
                                    {replaceCommaLine(el)}
                                  </span>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              <hr className={styles.hr_break_line} />
              {/* height:2px;border-width:0;color:gray;background-color:gray */}
              {
                // for table2
                !Loading && Data[0].table2 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table2[0].title}
                    </h1>
                    <img
                      alt={Data[0].table2[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table2[0].image_code}`}
                    />
                    <h3 style={{ textAlign: "left", marginBottom: "-4%" }}>
                      {Data[0].table2[0].tableTitle}
                    </h3>
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table2[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table2[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object" ? (
                                      `${el.pdf}, ${el.stp}, ${el.dxf}`
                                    ) : (
                                      <span>
                                        <br />
                                        {replaceCommaLine(el)}
                                      </span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table3
                !Loading && Data[0].table3 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table3[0].title}
                    </h1>
                    {!Loading && (
                      <h1
                        className="about-content-title"
                        style={{ textAlign: "center" }}
                      >
                        {Data[0].table3[0].subtitle}
                      </h1>
                    )}
                    <img
                      alt={Data[0].table3[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table3[0].image_code}`}
                    />
                    <h3 style={{ textAlign: "left", marginBottom: "-4%" }}>
                      {Data[0].table3[0].tableTitle}
                    </h3>
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table3[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}{" "}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table3[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object" ? (
                                      `${el.pdf}, ${el.stp}, ${el.dxf}`
                                    ) : (
                                      <span>
                                        <br />
                                        {replaceCommaLine(el)}
                                      </span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table4
                !Loading && Data[0].table4 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table4[0].title}
                    </h1>
                    <img
                      alt={Data[0].table4[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table4[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table4[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table4[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table5
                !Loading && Data[0].table5 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table5[0].title}
                    </h1>
                    <img
                      alt={Data[0].table5[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table5[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table5[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table5[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table6
                !Loading && Data[0].table6 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table6[0].title}
                    </h1>
                    <img
                      alt={Data[0].table6[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table6[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table6[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table6[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table7
                !Loading && Data[0].table7 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table7[0].title}
                    </h1>
                    <img
                      alt={Data[0].table7[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table7[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table7[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}{" "}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table7[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table8
                !Loading && Data[0].table8 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table8[0].title}
                    </h1>
                    <img
                      alt={Data[0].table8[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table8[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table8[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table8[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table9
                !Loading && Data[0].table9 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table9[0].title}
                    </h1>
                    <img
                      alt={Data[0].table9[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table9[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table9[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}{" "}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table9[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table10
                !Loading && Data[0].table10 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table10[0].title}
                    </h1>
                    <img
                      alt={Data[0].table10[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table10[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table10[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table10[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table11
                !Loading && Data[0].table11 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table11[0].title}
                    </h1>
                    <img
                      alt={Data[0].table11[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table11[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table11[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table11[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr className={styles.hr_break_line} />
                  </>
                )
              }

              {
                // for table12
                !Loading && Data[0].table12 && (
                  <>
                    <h1
                      className="about-content-title mt-5"
                      style={{ textAlign: "center" }}
                    >
                      {Data[0].table12[0].title}
                    </h1>
                    <img
                      alt={Data[0].table12[0].title}
                      src={`${BASE_IMG_URL}/${Data[0].table12[0].image_code}`}
                    />
                    <table>
                      <thead className="product-table-column">
                        <tr>
                          {Data[0].table12[0].identifier.map((element) => {
                            return (
                              <th style={{ width: `${element.width}%` }}>
                                {element.bigFont}
                                {element.smallFont && (
                                  <b
                                    style={{ fontSize: "10px", width: "30px" }}
                                  >
                                    {element.smallFont}
                                  </b>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Data[0].table12[0].value.map((element) => {
                          return (
                            <tr>
                              {element.map((el) => {
                                return (
                                  <td>
                                    {typeof el === "object"
                                      ? `${el.pdf}, ${el.stp}, ${el.dxf}`
                                      : el}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <hr />
                  </>
                )
              }
            </div>
          </div>
        </div>
        {/* <SideBanner />
      <About /> */}
        {/* <div className="container border-1">
        <div className="row ">
          <div className="col-md-3 border-1 d-flex align-items-end ">
            <SideBanner parentCallback={handleCallback} />
          </div>
          <div className="col-md-8 " style={{ marginTop: "2%" }}>
            {navId === 0 && <Banner />}
            {navId === 1 && <About />}
            {navId === 2 && <Products />}
            {navId === 3 && <Infrastructure />}
            {navId === 4 && <NewsAndEvents />}
            {navId === 5 && <Catalogue />}
          </div>
        </div>
      </div> */}
        {/* <div className="container ">
        <div className="row">
          <div className="col-md-10 " style={{ marginTop: "2%" }}>
            {navId === 0 && <Banner />}
            {navId === 1 && <About />}
            {navId === 2 && <Products />}
            {navId === 3 && <Infrastructure />}
            {navId === 4 && <NewsAndEvents />}
            {navId === 5 && <Catalogue />}
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <SideBanner parentCallback={handleCallback} />
          </div>
        </div>
      </div> */}
      </div>
      {/* <br /> */}
    </>
  );
};

export default ProductPage;
