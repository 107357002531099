import React from "react";
import Slider from "react-slick";
import styles from "./NewsEvents.module.css";
import { Button, Form, Modal } from "react-bootstrap";
import image from "./engimach.png";
import images from "./news-logo.png";
export default function NewsInfrastructure() {
  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const news = [
    {
      id: 1,
      heading: `Tool Discs0`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 3,
      heading: `Tool Discs1`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 3,
      heading: `Tool Discs2`,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 4,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
    {
      id: 5,
      title: "Slotted Type",
      content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui",
      buttonText: "sphoorti",
    },
  ];

  return (
    <>
      {/* <div className="news-events-main"></div> */}
      <div className={styles.DictionaryMainContainer}>
        <h1>News</h1>

        <div
          className={`${styles.DictionaryContainer} ${styles.NewsEventsContainer}`}
        >
          {/* <div className="container">
            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/NEWS/engimach_wJZMeEEVh.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648562219163" />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>ENGIMACH 2021</h3>
                <p>
                  Sphoorti will be participating in ENGIMACH 2021, which is held
                  from 1st December To 5th December at Helipad Exhibition
                  Centre, Gandhinagar, Gujarat, in Hall 2, Stall 83A.
                </p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1">
                <h3>intec 2019</h3>
                <p style={{ marginTop: "20px" }}>
                  Sphoorti will be participating in intec 2019 which is to be
                  held from 6th June to 10th June 2019 at CODISSIA Trade Fair
                  Complex, Coimbatore, India in Hall – E, STALL NO -9 & 10 .
                </p>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/NEWS/INTEX_T3nqevvdL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648562218086" />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/NEWS/RAJKOT_dEZgfxS8X.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648562218328" />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>RMTS 2018</h3>
                <p>
                  Sphoorti will be participating in ENGIMACH 2021, which is held
                  from 1st December To 5th December at Helipad Exhibition
                  Centre, Gandhinagar, Gujarat, in Hall 2, Stall 83A.
                </p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1">
                <h3>SUBCON 2018</h3>
                <p style={{ marginTop: "20px" }}>
                  From 5th – 7th June 2018 our company will be participating in
                  SUBCON 2018 which is to be held in Birmingham.
                </p>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/NEWS/SUBCON__1__VbtzvU-sv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648562586881" />
              </div>
            </div>
          </div> */}
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 order-1 order-md-1 mt-3">
                <img src="https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/News/BEST_BRANDS_YUutPWsQw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652852999994" />
              </div>
              <div
                className="col-12 col-md-8 col-lg-8 order-2 order-md-2 mt-3"
                // style={{ textAlign: " center " }}
              >
                <h3> 2021</h3>
                <p>Awarded Best metal cutting brand</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-12 col-md-8 col-lg-8 order-2 order-md-1 infrastructureimageright"
                // style={{ textAlign: " center " }}
              >
                <h3>2021</h3>
                <p style={{ marginTop: "20px" }}>Completed 25 years</p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 order-1 order-md-2">
                <img
                  className="infrastructureimagerightimg"
                  src="https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/News/SILVER_JUBLIEE_bvMHKVq-I.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652852998782"
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 order-1 order-md-1">
                <img src="https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/News/BEST_BRANDS_1_EGVBCInrC.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652853000289" />
              </div>
              <div className="col-12 col-md-8 col-lg-8 order-2 order-md-2">
                <h3>2020</h3>
                <p>Sphoorti has been awarded as Best Metal Cutting Brand </p>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      {/* <div className="news-main">
        <h3>News</h3>
        <Slider {...settings}>
          {news.map((value, index) => (
            <div className="news-item" key={index}>
              <div className="news-card-container">
                <div className="news-card-title">{value.title}</div>
                <div className="news-card-desc">{value.content}</div>
                <div className="news-card-button">{value.buttonText}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="events-main">
        <Slider {...settings}>
          {news.map((value, index) => (
            <div className="news-item" key={index}>
              <div className="news-card-container">
                <div className="news-card-title">{value.title}</div>
                <div className="news-card-desc">{value.content}</div>
                <div className="news-card-button">{value.buttonText}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
      {/* <div className="events-main">
        <h3>Events </h3>
        <Slider {...settings}>
          {events.map((value, index) => (
            <div className="news-item" key={index}>
              <div className="news-card-container">
                <div className="news-card-title">{value.title}</div>
                <div className="news-card-desc">{value.content}</div>
                <div className="news-card-button">{value.buttonText}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
    </>
  );
}
