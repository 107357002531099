import React, { useEffect, useRef, useState } from "react";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import SideBanner from "./SideBanner";
import "../Home/style.css";
import HeaderBox from "../Home/HeaderBox";
import News from "./News";
import Events from "./Events";

const NewsEventsPage = ({
  navigationId,
  setNavigationId,
  setParentProduct,
}) => {
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  const [ScrollVal, setScrollVal] = useState(1);
  const [AllowScroll, setAllowScroll] = useState(true);
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "banner")
      : (windowSizeResolution.current.id = "banner-1366");
  }, []);
  // const [navId, setNavId] = useState(0);
  const handleCallback = (childdata) => {
    setNavigationId(childdata);
  };
  useEffect(() => {
    if (navigationId === 2) {
      setScrollVal(60);
    }
  }, []);
  useEffect(() => {
    // console.log(ScrollVal);
    if (ScrollVal < 50) {
      setNavigationId(1);
    }
    if (ScrollVal >= 50) {
      setNavigationId(2);
    }
  }, [ScrollVal]);
  const handleWheel = (e) => {
    if (AllowScroll) {
      if (e.deltaY > 0) {
        if (navigationId < 2) {
          setNavigationId(navigationId + 1);
          setAllowScroll(false);
          // console.log(e);
        }
      } else {
        if (navigationId > 1) {
          setNavigationId(navigationId - 1);
          setAllowScroll(false);
        }
      }
    }
  };
  useEffect(() => {
    setTimeout(() => setAllowScroll(true), 1000);
  }, [navigationId]);
  const downHandler = ({ key }) => {
    if (AllowScroll) {
      if (key === "ArrowDown") {
        if (navigationId < 2) {
          setNavigationId(navigationId + 1);
          setAllowScroll(false);
        }
      }
      if (key === "ArrowUp") {
        if (navigationId > 1) {
          setNavigationId(navigationId - 1);
          setAllowScroll(false);
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", downHandler);
    // window.addEventListener("keyup", upHandler);
  });
  return (
    <>
      <div
        className="rest-banner "
        ref={windowSizeResolution}
        // onWheel={(e) => handleWheel(e)}
      >
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className="container-fluid mt-5 mt-lg-1 ">
          <div className="row min_height">
            <div className="col-12 col-lg-3 d-flex align-items-end">
              <SideBanner
                parentCallback={handleCallback}
                navigationId={navigationId}
              />
            </div>
            <div className="col-12 col-lg-9 ">
              {/* {navId === 0 && <Banner />} */}
              {navigationId === 1 && <News />}
              {navigationId === 2 && <Events />}
            </div>
          </div>
        </div>
        {/* <SideBanner />
        <About /> */}
        {/* <div className="container border-1">
          <div className="row ">
            <div className="col-md-3 border-1 d-flex align-items-end ">
              <SideBanner parentCallback={handleCallback} />
            </div>
            <div className="col-md-8 " style={{ marginTop: "2%" }}>
              {navId === 0 && <Banner />}
              {navId === 1 && <About />}
              {navId === 2 && <Products />}
              {navId === 3 && <Infrastructure />}
              {navId === 4 && <NewsAndEvents />}
              {navId === 5 && <Catalogue />}
            </div>
          </div>
        </div> */}
        {/* <div className="container ">
          <div className="row">
            <div className="col-md-10 " style={{ marginTop: "2%" }}>
              {navId === 0 && <Banner />}
              {navId === 1 && <About />}
              {navId === 2 && <Products />}
              {navId === 3 && <Infrastructure />}
              {navId === 4 && <NewsAndEvents />}
              {navId === 5 && <Catalogue />}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <SideBanner parentCallback={handleCallback} />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default NewsEventsPage;
