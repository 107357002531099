import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
const SideBanner = ({ parentCallback, navigationId }) => {
  // const windowSize = useRef(null);
  // useEffect(() => {
  //   window.innerWidth === 1536
  //     ? (windowSize.current.className = "arrow-links")
  //     : (windowSize.current.className = "arrow-links-1366");
  // }, []);
  const handleClick = (id) => {
    parentCallback(id);
  };
  let item = "page-links-hover-button";
  return (
    <>
      <div className="side-banner-links">
        <div className="page-links page-links-news">
          <button
            onClick={(id) => handleClick(1)}
            style={{ fontWeight: navigationId === 1 ? "bolder" : "normal" }}
            className={navigationId == 1 && item}
          >
            News
          </button>
          <button
            onClick={(id) => handleClick(2)}
            style={{ fontWeight: navigationId === 2 ? "bolder" : "normal" }}
            className={navigationId == 2 && item}
          >
            Events
          </button>
          <button
            onClick={(id) => handleClick(2)}
            style={{ fontWeight: navigationId === 2 ? "bolder" : "normal" }}
            className="button-content-remove"
          ></button>
          <button
            onClick={(id) => handleClick(2)}
            style={{ fontWeight: navigationId === 2 ? "bolder" : "normal" }}
            className="button-content-remove"
          ></button>
          <button
            onClick={(id) => handleClick(2)}
            style={{ fontWeight: navigationId === 2 ? "bolder" : "normal" }}
            className="button-content-remove"
          ></button>
          <button
            onClick={(id) => handleClick(2)}
            style={{ fontWeight: navigationId === 2 ? "bolder" : "normal" }}
            className="button-content-remove"
          ></button>
          {/* <button
            onClick={(id) => handleClick(3)}
            style={{ fontWeight: navigationId === 3 ? "bolder" : "normal" }}
          >
            Certificates
          </button> */}
          {/* <a exact className="active" href="/">
            Home
          </a>
          <a href="#productsmy">Company</a>
          <a href="/product/2">Products</a>
          <a href="/product/3">Infrastructure</a>
          <a href="/product/4">News&nbsp;&&nbsp;Events</a>
          <a href="/product/4">Catalog</a> */}
        </div>
        {/* <div className="arrow-links" ref={windowSize}>
          <div className="flex-view">
            <div className="social-links">
              <Link to="">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="">
                <i className="fab fa-facebook"></i>
              </Link>
              <Link to="">
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
            <div className="arrow-line">
              <i className="fas fa-greater-than"></i>
            </div>
            <div className="arrow-link-text">
              <span>Find us on</span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default SideBanner;
