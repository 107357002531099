import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import "../Home/style.css";
import HeaderBox from "../Home/HeaderBox";
import styles from "./Catalog.module.css";
import logo from "./sphoorti-logos.png";
import Catologue from "../Home/Catologue";
import axios from "axios";

const Catalog = ({
  navigationId,
  setNavigationId,
  setProductData,
  setParentProduct,
}) => {
  const CatogueContent = [
    {
      id: 1,
      title: `SLOTTED TOOL DISCS & TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/slotted-tool-disc_1gXLabjAN.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703468416`,
      imgName: "product_img.png",
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/1._SLOTTED_TOOL_DISCS___TOOL_HOLDERS_2019_compressed_NLM16LCQr.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652962607484",
    },
    {
      id: 2,
      title: `VDI TOOL DISCS & TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/vdi-td-th-13_uHphSHcgo.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703468579`,
      imgName: "product_img.png",
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/2_VDI_TD___TH_Final_Dec_2021-compressed_hl7J9itBG.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652268806196",
    },
    {
      id: 3,
      title: `DRIVEN TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/driven-tool-holder_lCEqKZFvw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703467261`,
      imgName: "product_img.png",
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/5_Driven_Tool_Holders_Final_Dec_2021_qJzAyrPNF.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652172476178",
    },
    {
      id: 4,
      title: `BMT TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/bmt-tool-holders_ip4kYWH_V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703467081`,
      imgName: "product_img.png",
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/3_BMT_Tool_Holders_Final_Dec_2021_9XXgJPgOy.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652172485197",
    },
    {
      id: 5,
      title: `VTP TOOL HOLDERS`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/vtp-tool-holder_PbmifuPTh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703468828`,
      imgName: "product_img.png",
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/4_VTP_Final_Dec_2021_ipujwYzHU.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652172460063",
    },

    {
      id: 6,
      title: `REDUCTION SLEEVES`,
      imageURL: `https://ik.imagekit.io/0rsvz9vomoe/catalog_home/reduction-sleeves_TW7sEJF44XHW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1645703467550`,
      imgName: "product_img.png",
      pdfLink:
        "https://ik.imagekit.io/0rsvz9vomoe/Sphoorti/assets/6_Reduction_Sleeves_Final_Dec_2021_dMY8je8_F.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652172483592",
    },
  ];
  const [show, setShow] = useState({ show: false, pdfId: "1" });
  const [EmailValue, setEmailValue] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [CompanyNameValue, setCompanyNameValue] = useState("");
  const [MobileValue, setMobileValue] = useState("");
  const [dataCollected, setDataCollected] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (localStorage.getItem("dataCollected")) {
      setDataCollected(true);
    }
    const x = localStorage.getItem("dataCollected");
    console.log(x);
  }, []);

  const pdfload = () => {
    const pdfItem = CatogueContent.filter(
      (item) => item.id === parseInt(show.pdfId)
    );

    setDataCollected(true);
    localStorage.setItem("dataCollected", true);
    window.open(
      `${pdfItem[0].pdfLink}`,
      console.log(pdfItem[0].pdfLink, "xyz")
    );
  };
  console.log(show);

  const handleShow = (id) => {
    if (dataCollected) {
      setShow((prevValues) => {
        return {
          // show: true,
          pdfId: id,
        };
      });

      pdfload();
    } else {
      setShow((prevValues) => {
        return {
          show: true,
          pdfId: id,
        };
      });
    }
    // pdfId = id;
  };
  const handleSubmit = () => {
    sessionStorage.setItem("item_key", "item_value");
    var item_value = sessionStorage.getItem("item_key");
    setShow(false);
    var payload = {
      service_id: "service_6y2mx6c",
      template_id: "template_ym3e0z5",
      user_id: "Za3wa01HeoeXmQqsY",
      template_params: {
        html_message: `
              customer email: ${EmailValue},
              customer name: ${NameValue},
              company name: ${CompanyNameValue},
              mobile number: ${MobileValue}
            `,
        mail_title: `
              Customer info from catalogue
            `,
      },
    };
    axios({
      method: "post",
      url: "https://api.emailjs.com/api/v1.0/email/send",
      data: JSON.stringify(payload), // you are sending body instead
      headers: {
        // 'Authorization': `bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // window.open(
    //   "https://ik.imagekit.io/syeovulacjk/sptools/asset/docs/pdf/2_IVcDPVtHpLV.pdf"

    // );
    pdfload();
  };

  const handleEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const handleName = (e) => {
    setNameValue(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyNameValue(e.target.value);
  };
  const handleMobile = (e) => {
    setMobileValue(e.target.value);
  };

  const windowSizeResolution = useRef(null, "windowSizeResolution");
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "rest-banner")
      : (windowSizeResolution.current.id = "rest-banner");
  }, []);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const [navId, setNavId] = useState(1);
  // const handleCallback = (childdata) => {
  //   setNavigationId(childdata);
  // };
  // console.log(Arr[0].megaMenu[0].subMenu);
  return (
    <>
      <div className="product-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className={styles.DictionaryMainContainer}>
          <h2>Catalogues</h2>
          <div className="min_height">
            <div className={styles.DictionaryContainer}>
              {CatogueContent.map((value, index) => (
                <div
                  className={styles["catologue-container"]}
                  key={value.id}
                  onClick={() => handleShow(value.id)}
                >
                  <img
                    src={value.imageURL}
                    alt="Avatar"
                    className={styles["catologue-image"]}
                    width="10%"
                  />
                  <h6>{value.title}</h6>
                </div>
                // </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Catologues Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                onChange={(e) => handleName(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => handleEmail(e)}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company Name"
                onChange={(e) => handleCompanyName(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Mobile Number"
                onChange={(e) => handleMobile(e)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={
              NameValue.length < 3 ||
              EmailValue.length < 5 ||
              CompanyNameValue.length < 3 ||
              MobileValue.length < 10
            }
          >
            Download Catalogue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Catalog;
