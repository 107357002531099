import React from "react";
import Slider from "react-slick";
import image from "../NewsEvents/engimach.png";
// import product_img from "./assets/tool2.png";
import infra_1 from "../Home/assets/infrastructure/infra-1.jpg";
import infra_2 from "../Home/assets/infrastructure/infra-2.jpg";
import infra_3 from "../Home/assets/infrastructure/infra-3.jpg";
import infra_4 from "../Home/assets/infrastructure/infra-4.jpg";
import infra_5 from "../Home/assets/infrastructure/infra-5.jpg";
import infra_6 from "../Home/assets/infrastructure/infra-6.jpg";
import infra_7 from "../Home/assets/infrastructure/infra-7.jpg";
import infra_8 from "../Home/assets/infrastructure/infra-8.jpg";
import infra_9 from "../Home/assets/infrastructure/infra-9.jpg";
import styles from "./Infrastructure.module.css";
import logo from "./assets/logo.png";
import fireTraning from "./assets/fireb-training.jpg";

import sphoorti_3 from "./assets/sphoorti_3.jpg";
import sphoorti_4 from "./assets/sphoorti_4.jpg";
import sphoorti_5 from "./assets/sphoorti_5.jpg";
import sphoorti_6 from "./assets/sphoorti_6.jpg";
import sphoorti_7 from "./assets/sphoorti_7.jpg";
import sphoorti_8 from "./assets/sphoorti_8.jpg";
import sphoorti_9 from "./assets/sphoorti_9.jpg";
import sphoorti_10 from "./assets/sphoorti_10.jpg";
import sphoorti_11 from "./assets/sphoorti_11.jpg";

export default function Products() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const infrastructureContent = [
    {
      name: "2022",
      desc: "Winners of online Kaizen competition ",
      // image: `${sphoorti_9}`,
      image:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/Kaizen_hgSKUlNoa.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652780246399",
    },
    {
      name: "2021",
      desc: "Completed 25 years",
      image:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Gallery/SILVER_JUBLIEE_uyoyqg6x_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652851789950",
    },
    {
      name: "2020",
      desc: "<div><ul><b>The Economic times</b><li>Awarded Best metal cutting brand</li></ul></div>",
      image:
        "https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Gallery/BEST_BRANDS_1_82viZFvNgE.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652852011718",
    },
    {
      name: "2020",
      desc: "<div>Health Checkup camp organised in our company </div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/Health_Chk_6icUbewaT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781111064`,
    },
    {
      name: "2019",
      desc: "<div>winners of the volley ball tournment event</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/VolleyBall_NA1y4aGXXf.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781193042`,
    },
    {
      name: "2019",
      desc: "<div>yoga day conducted in our factory</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/YogaDay_JRHlUCEWBg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781193481`,
    },
    {
      name: "2019",
      desc: "<div>women's day celebrated in our company to promote women empowerment</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/WomensDay_cravUPx6I.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781192210`,
    },
    {
      name: "2018",
      desc: "<div>Basics of fire fighting traning conducted for our employees</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/Fire_Fight_VWn2tBeB_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781110661`,
    },
    {
      name: "2018",
      desc: "<div>certificate for having organised voluntary blood donation camp</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/MKL_BloodDon_lyYcgtU_UU.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781191423`,
    },
    {
      name: "2018",
      desc: "<div>certificate for having arranged for voluntary blood donation camp</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/LC_BloodDon1_uhiqLounRd.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781192490`,
    },
    {
      name: "2018",
      desc: "<div>certificate for having arranged for voluntary blood donation camp</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/IndGan_BloodDon_bNeDmPOgB.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781111540`,
    },
    {
      name: "2018",
      desc: "<div>certificate for having arranged for voluntary blood donation camp</div>",
      image: `https://ik.imagekit.io/znuaavymv/Sphoorti_Main_Website/Events/LC_BloodDon_AF9gBZGcOJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652781192256`,
    },
    {
      name: "2006",
      desc: "<div><ul><li>Moved to a new Premises with total area of 1 Acre and Build up area of 23600 Sq.ft</li></ul></div><div>",
      image:
        "https://ik.imagekit.io/0rsvz9vomoe/gallerry/INAG-1_roP_D8e9V.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646721906399",
    },
    {
      name: "1996",
      desc: "<ul><li> Incorporated in 1996</li><li>with a built up area of 5000 Sq.ft</li><li>Sphoorti started it's operations by manufacturing Slotted Tool Disc & Static Tool Holders</li></ul></div>",
      image: `${logo}`,
    },
  ];

  return (
    <>
      {/* <div className={styles.DictionaryMainContainer}> */}
      <div className={styles.DictionaryMainContainer}>
        <h1>Gallery</h1>
        {/* {infrastructureContent.map((item) => {
         return()
        })} */}
        <div className={styles.DictionaryContainer}>
          {/* {infrastructureContent.map((item) => {
            return (
              <div className={styles.RowContainer}>
                <h3>{item.name}</h3>
                <img src={item.image} />
                <div
                  className={styles.Description}
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                ></div>
              </div>
            );
          })} */}
          <div className={` container-fluid  ${styles.GalleryContainers}`}>
            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src={infrastructureContent[0].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[0].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[0].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 infrastructureimageright">
                <h3>{infrastructureContent[1].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[1].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src={infrastructureContent[1].image} />
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src={infrastructureContent[2].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[2].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[2].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 infrastructureimageright">
                <h3>{infrastructureContent[3].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[3].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src={infrastructureContent[3].image} />
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src={infrastructureContent[4].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[4].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[4].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 infrastructureimageright">
                <h3>{infrastructureContent[5].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[5].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src={infrastructureContent[5].image} />
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src={infrastructureContent[6].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[6].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[6].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 infrastructureimageright">
                <h3>{infrastructureContent[7].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[7].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src={infrastructureContent[7].image} />
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src={infrastructureContent[8].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[8].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[8].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 infrastructureimageright">
                <h3>{infrastructureContent[9].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[9].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src={infrastructureContent[9].image} />
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1 ">
                <img src={infrastructureContent[10].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[10].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[10].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1 infrastructureimageright">
                <h3>{infrastructureContent[11].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[11].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img src={infrastructureContent[11].image} />
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-1">
                <img src={infrastructureContent[12].image} />
              </div>
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-2">
                <h3>{infrastructureContent[12].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[12].desc,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 order-2 order-md-1">
                <h3>{infrastructureContent[13].name}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: infrastructureContent[13].desc,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 order-1 order-md-2">
                <img
                  src={infrastructureContent[13].image}
                  style={{ height: "50%" }}
                />
              </div>
            </div>
            <hr />

            <br />
            <br />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
