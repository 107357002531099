import React from "react";
import Styles from "./Blogs.module.css";
export default function Blogs({ Blogs }) {
  const { id, Heading, content } = Blogs[0];
  console.log(Blogs);
  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: "2%" }}>{Heading}</h2>
      <div
        // style={{ MarginLeft: "10%" }}
        className={Styles.blogSingleItemContainer}
      >
        <div className={Styles.blogSingleItem}>
          {/* <h1>{Heading}</h1> */}
          <div className="container-fluid">
            <div className="row">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
