import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./Locators.module.css";

function Locators() {
  const [show, setShow] = useState(false);
  const [Content, setContent] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (element) => {
    setContent(element);
    setShow(true);
  };
  return (
    <>
      {" "}
      <h2>International Dealers</h2>
      <div className={styles.DictionaryContainer}>
        <div
          className={styles.CareersContent}
          // style={{ justifyContent: "space-between", marginLeft: "30px" }}
        >
          <div className={styles.CareersInfo}>
            {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.277779295862!2d77.50637231463595!3d13.01797461738169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3cfb13c4f325%3A0xca7dd57460cfefff!2sSphoorti%20Machine%20Tools%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1639632168681!5m2!1sen!2sin"

            // style="border:0;"
            // allowfullscreen=""
            // loading="lazy"
          ></iframe> */}
            <img src="https://ik.imagekit.io/syeovulacjk/sptools/asset/company/logo/Sphoorti.png" />
          </div>
          <div className={styles.AddresContainer}>
            <h4>Sphoorti Machine Tools Pvt. Ltd.</h4>
            <h6>
              #18, Plot No. 467, 469, 12th Cross, IV Phase, Peenya Industrial
              Area, Peenya, Bengaluru - 560058. INDIA Ph: +91 8041272768
              Email:info@sphoorti.com
            </h6>
          </div>
        </div>
        <div className="d-none">
          <Button
            className="btn btn-secondary btn-lg"
            onClick={() => handleShow("east")}
          >
            EAST
          </Button>
          <Button
            className="btn btn-secondary btn-lg"
            onClick={() => handleShow("west")}
          >
            WEST
          </Button>
          <Button
            className="btn btn-secondary btn-lg"
            onClick={() => handleShow("north")}
          >
            NORTH
          </Button>
          <Button
            className="btn btn-secondary btn-lg"
            onClick={() => handleShow("south")}
          >
            SOUTH
          </Button>
          <Button
            className="btn btn-secondary btn-lg"
            onClick={() => handleShow("international")}
          >
            INTERNATIONAL
          </Button>
        </div>
        {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>OUR LOCATORS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1>{show && Content}</h1>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      </div>
    </>
  );
}

export default Locators;
