import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

// import logo from "./assets/logo.png";
const Navbar = ({ setNavigationId, setParentProduct }) => {
  const [ShowDiv, setShowDiv] = useState("");
  const [ShowSubDiv, setShowSubDiv] = useState("");
  let history = useHistory();
  const handleSubMenuClick = (index, string) => {
    index && setNavigationId(index);
    history.push(`/${string}`);
  };
  const handleProductClick = (prop) => {
    setParentProduct(prop);
    localStorage.setItem("ParentProduct", JSON.stringify(prop));
    localStorage.setItem("ParentProductCode", JSON.stringify(prop[0].code));
    history.push(`/${prop[0].parent}`);
    localStorage.setItem("parentBase", JSON.stringify(prop[0].parent));
    window.location.reload();
  };
  const WindowWidth = window.innerWidth >= 992;

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light  py-0  navbar-main-container">
        <div
          className="container"
          id="navbar-margin-container"
          style={{ marginRight: "0%" }}
        >
          <Link className="navbar-brand" to="/">
            {/* <img src={logo} alt="logo" id="logo" /> */}
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            style={{ justifyContent: "center" }}
          >
            <ul className="navbar-nav  mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  activeClassName={WindowWidth ? "active" : ""}
                  exact
                  className="nav-link"
                  to="/"
                  style={{ fontSize: "12px" }}
                >
                  HOME
                </NavLink>
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => setShowDiv("company")}
                onMouseLeave={() => setShowDiv("")}
              >
                <NavLink
                  style={{ fontSize: "12px" }}
                  exact
                  activeClassName={WindowWidth ? "active" : ""}
                  className="nav-link"
                  to="/company"
                >
                  COMPANY
                </NavLink>
                {ShowDiv === "company" && (
                  <div className="nav-link-pop-up">
                    <span onClick={() => handleSubMenuClick(1, "company")}>
                      Company Overview
                    </span>
                    <span onClick={() => handleSubMenuClick(2, "company")}>
                      Vision & Mission
                    </span>
                    <span onClick={() => handleSubMenuClick(3, "company")}>
                      Certificates
                    </span>
                  </div>
                )}
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => setShowDiv("product")}
                onMouseLeave={() => setShowDiv("")}
              >
                <div
                  exact
                  activeClassName={WindowWidth ? "active " : ""}
                  className="nav-link"
                  // to="/product-toolDiscs-vdiToolDiscs"
                  to={WindowWidth ? "/product-toolDiscs-vdiToolDiscs" : "#"}
                  data-toggle="dropdown"
                  // style={{ fontSize: "12px" }}
                >
                  PRODUCT
                </div>
                {ShowDiv === "product" && (
                  <div className="nav-link-pop-up">
                    <span
                      onMouseEnter={() => setShowSubDiv("tool discs")}
                      onMouseLeave={() => setShowSubDiv("")}
                      // onClick={() =>
                      //   handleProductClick([
                      //     {
                      //       title: "SLOTTED TOOL DISC",
                      //       description: null,
                      //       code: "PRO0000001",
                      //     },
                      //   ])
                      // }
                    >
                      {ShowSubDiv === "tool discs" && (
                        <div className="nav-link-sub-pop-up">
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "SLOTTED TOOL DISC",
                                  description: null,
                                  code: "PRO0000002",
                                  parent: "tool-disc",
                                },
                              ])
                            }
                          >
                            Slotted Tool Discs
                          </span>
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "VDI TOOL DISC",
                                  description: null,
                                  code: "PRO0000008",
                                  parent: "tool-disc",
                                },
                              ])
                            }
                          >
                            VDI Tool Discs
                          </span>
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "BMT TOOL DISC",
                                  description: null,
                                  code: "PRO0000189",
                                  parent: "tool-disc",
                                },
                              ])
                            }
                          >
                            BMT Tool Disc
                          </span>
                        </div>
                      )}
                      Tool Discs →
                    </span>
                    <span
                      onMouseEnter={() => setShowSubDiv("tool holders")}
                      onMouseLeave={() => setShowSubDiv("")}
                    >
                      {ShowSubDiv === "tool holders" && (
                        <div className="nav-link-sub-pop-up">
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "BOT HOLDER",
                                  description: null,
                                  code: "PRO0000019",
                                  parent: "tool-holder",
                                },
                              ])
                            }
                          >
                            BOT HOLDER
                          </span>
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "VDI TOOL HOLDER",
                                  description: null,
                                  code: "PRO0000020",
                                  parent: "tool-holder",
                                },
                              ])
                            }
                          >
                            VDI TOOL HOLDER
                          </span>
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "BMT TOOL HOLDER",
                                  description: null,
                                  code: "PRO0000021",
                                  parent: "tool-holder",
                                },
                              ])
                            }
                          >
                            BMT TOOL HOLDER
                          </span>

                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "VTP TOOL HOLDER",
                                  description: null,
                                  code: "PRO0000022",
                                  parent: "tool-holder",
                                },
                              ])
                            }
                          >
                            VTP TOOL HOLDER
                          </span>
                          <span
                            onClick={() =>
                              handleProductClick([
                                {
                                  title: "BAR PULLER",
                                  description: null,
                                  code: "PRO0000023",
                                  parent: "tool-holder",
                                },
                              ])
                            }
                          >
                            BAR PULLER
                          </span>
                        </div>
                      )}
                      Tool Holders →
                    </span>
                    <span
                      onClick={() =>
                        handleProductClick([
                          {
                            title: "Accessories",
                            description: null,
                            code: "PRO0000173",
                            parent: "accessories",
                          },
                        ])
                      }
                    >
                      Accessories
                    </span>
                    {/* <span
                      onClick={() => handleProductClick("customDesignedTool")}
                    >
                      Custom Designed Tool
                    </span> */}
                    {/* start */}

                    <span
                      onMouseEnter={() => setShowSubDiv("custom-designed-tool")}
                      onMouseLeave={() => setShowSubDiv("")}
                    >
                      {ShowSubDiv === "custom-designed-tool" && (
                        <div className="nav-link-sub-pop-up">
                          <span>
                            <NavLink
                              className="cutom-nav-item"
                              to="/customDesignedTool/tool-holder"
                            >
                              Tool holder
                            </NavLink>
                          </span>

                          <span>
                            <NavLink
                              className="cutom-nav-item"
                              to="/product-customDesignedTool/tool-disc"
                            >
                              Tool Disc
                            </NavLink>
                          </span>
                        </div>
                      )}
                      Custom Designed Tool →
                    </span>

                    {/* End */}
                  </div>
                )}
              </li>

              <li className="nav-item">
                <NavLink
                  style={{ fontSize: "12px" }}
                  exact
                  activeClassName={WindowWidth ? "active" : ""}
                  className="nav-link"
                  to="/infrastructure"
                >
                  INFRASTRUCTURE
                </NavLink>
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => setShowDiv("news-events")}
                onMouseLeave={() => setShowDiv("")}
              >
                <NavLink
                  style={{ fontSize: "12px" }}
                  exact
                  activeClassName={WindowWidth ? "active" : ""}
                  className="nav-link"
                  // to="/news-events"
                  to={WindowWidth ? "/news-events" : "#"}
                >
                  NEWS & EVENTS
                </NavLink>
                {ShowDiv === "news-events" && (
                  <div className="nav-link-pop-up" style={{ width: "130px" }}>
                    <span onClick={() => handleSubMenuClick(1, "news-events")}>
                      News
                    </span>
                    <span onClick={() => handleSubMenuClick(2, "news-events")}>
                      Events
                    </span>
                  </div>
                )}
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => setShowDiv("resources")}
                onMouseLeave={() => setShowDiv("")}
              >
                <NavLink
                  style={{ fontSize: "12px" }}
                  exact
                  activeClassName={WindowWidth ? "active" : ""}
                  className="nav-link"
                  // to="/resources"
                  to={WindowWidth ? "/resources" : "#"}
                >
                  RESOURCES
                </NavLink>
                {ShowDiv === "resources" && (
                  <div className="nav-link-pop-up" style={{ width: "130px" }}>
                    <span onClick={() => handleSubMenuClick(1, "resources")}>
                      Gallery
                    </span>
                    <span onClick={() => handleSubMenuClick(2, "blogs")}>
                      Blog
                    </span>
                  </div>
                )}
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  activeClassName={WindowWidth ? "active" : ""}
                  className="nav-link"
                  to="/careers"
                  style={{ fontSize: "12px" }}
                >
                  CAREER
                </NavLink>
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => setShowDiv("contact-us")}
                onMouseLeave={() => setShowDiv("")}
              >
                <NavLink
                  exact
                  activeClassName={WindowWidth ? "active" : ""}
                  className="nav-link"
                  to="/contact-us"
                  style={{ fontSize: "12px" }}
                >
                  CONTACT
                </NavLink>
                {ShowDiv === "contact-us" && (
                  <div className="nav-link-pop-up" style={{ width: "130px" }}>
                    {/* <span onClick={() => handleSubMenuClick(1, "contact-us")}>
                      Contact Us
                    </span> */}
                    {/* <span onClick={() => handleSubMenuClick(2, "contact-us")}>
                      Locators
                    </span> */}
                  </div>
                )}
              </li>
              <li className="onlinestore">
                <a
                  style={{ fontSize: "12px", textAlign: "center" }}
                  className="nav-link"
                  target="_blank"
                  href="https://shop.sphoorti.in/"
                >
                  ONLINE STORE
                </a>
              </li>
              <li className="findyourtool">
                <a
                  style={{ fontSize: "12px", textAlign: "center" }}
                  target="_blank"
                  className="nav-link"
                  href=" https://sphoorti-tools.com/"
                >
                  FIND YOUR TOOL HOLDERS
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
