import React, { useEffect, useRef } from "react";
import Header from "../../../Home/Header";
import Navbar from "../../../commons/Navbar";
import "../../../Home/style.css";
import HeaderBox from "../../../Home/HeaderBox";
import styles from "./CareersStyle.module.css";
import img1 from "./infra-1.jpg";
const CustomDesignToolsDisc = ({
  navigationId,
  setNavigationId,
  setProductData,
  setParentProduct,
}) => {
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  useEffect(() => {
    window.innerWidth === 1536
      ? (windowSizeResolution.current.id = "rest-banner")
      : (windowSizeResolution.current.id = "rest-banner");
  }, []);
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const [navId, setNavId] = useState(1);
  // const handleCallback = (childdata) => {
  //   setNavigationId(childdata);
  // };
  // console.log(Arr[0].megaMenu[0].subMenu);
  return (
    <>
      <div className="rest-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3"></div>
            <div className={`${styles.customTitleh1} col-12 col-lg-9 `}>
              <h1>Tool Disc</h1>
              <div className={styles.DictionaryContainer}>
                <div className="row">
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TD1_EvVDZMD39AOU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255289266 " />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TD2_D6Nemg05_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255290103" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TD3_63ej3m4ul.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255290483" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TD4_9mxcgR0k9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255290676" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TD5_dPMUnAWaO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255290855" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/TD6_F4rbNUpZh.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647255291382" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/td10_C6c3eo3cU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647598179514" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/td13_OJ1EuqKX5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647597802717" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/td11_1TkDqeS-t.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647601341390" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/td12_ImaNOzmEB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647601592997" />
                    <h5></h5>
                    <p></p>
                  </div>
                  <div className="col-12  col-sm-5 col-md-4 ">
                    <img src="https://ik.imagekit.io/0rsvz9vomoe/Mameshwara/custom_design/td14_x2VZMnseo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647603509837" />
                    <h5></h5>
                    <p></p>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomDesignToolsDisc;
