import React, { useEffect, useRef, useState } from "react";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import "../Home/style.css";
import {} from "react-router-dom";
import HeaderBox from "../Home/HeaderBox";
import styles from "./ProductStyle.module.css";
// import Arr from "../assets/product.json";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FaHandPointRight } from "react-icons/fa";
const ParentProductPage = ({
  navigationId,
  setNavigationId,
  ParentProduct,
  setParentProduct,
  setProductData,
}) => {
  const parentData = JSON.parse(localStorage.getItem("ParentProduct"));
  const windowSizeResolution = useRef(null, "windowSizeResolution");
  const [Loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const parentProduct = JSON.parse(localStorage.getItem("ParentProduct"));
  // const IMG_URL =
  //   "https://ik.imagekit.io/syeovulacjk/sptools/asset/images/5a1.png";
  // const historyArray =[{code:"001",name:"axial-tool"},{code:"002",name:"radial-tool"}];

  let history = useHistory();

  let ParentProductProp =
    history.location.pathname === "/tool-disc/slotted-tool-disc"
      ? [
          {
            title: "SLOTTED TOOL DISC",
            description: null,
            code: "PRO0000002",
            parent: "tool-disc",
          },
        ]
      : history.location.pathname === "/tool-disc/vdi-tool-disc"
      ? [
          {
            title: "VDI TOOL DISC",
            description: null,
            code: "PRO0000008",
            parent: "tool-disc",
          },
        ]
      : history.location.pathname === "/tool-disc/bmt-tool-disc"
      ? [
          {
            title: "BMT TOOL DISC",
            description: null,
            code: "PRO0000189",
            parent: "tool-disc",
          },
        ]
      : history.location.pathname === "/tool-holder/bot-holder"
      ? [
          {
            title: "BOT HOLDER",
            description: null,
            code: "PRO0000019",
            parent: "tool-holder",
          },
        ]
      : history.location.pathname === "/tool-holder/vdi-tool-holder"
      ? [
          {
            title: "VDI TOOL HOLDER",
            description: null,
            code: "PRO0000020",
            parent: "tool-holder",
          },
        ]
      : history.location.pathname === "/tool-holder/bmt-tool-holder"
      ? [
          {
            title: "BMT TOOL HOLDER",
            description: null,
            code: "PRO0000021",
            parent: "tool-holder",
          },
        ]
      : history.location.pathname === "/tool-holder/vtp-tool-holder"
      ? [
          {
            title: "VTP TOOL HOLDER",
            description: null,
            code: "PRO0000022",
            parent: "tool-holder",
          },
        ]
      : history.location.pathname === "/tool-holder/bar-puller"
      ? [
          {
            title: "BAR PULLER",
            description: null,
            code: "PRO0000023",
            parent: "tool-holder",
          },
        ]
      : history.location.pathname === "/accessories/accessories"
      ? [
          {
            title: "Accessories",
            description: null,
            code: "PRO0000173",
            parent: "accessories",
          },
        ]
      : null;

  useEffect(() => {
    const urlSteps = parentData?.map((element) =>
      (element.title.match(/\d/) !== null
        ? element.title.slice(2)
        : element.title
      )
        .replace(/\s+/g, "-")
        .toLowerCase()
    );
    const stringURL = urlSteps?.reduce(
      (prevValue, element) => `${prevValue}/${element}`
    );
    localStorage.getItem("parentBase") &&
      stringURL &&
      history.push(
        `/${JSON.parse(localStorage.getItem("parentBase"))}/${stringURL}`
      );
  }, []);
  useEffect(() => {
    setParentProduct(parentData);
    axios
      .get(
        `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/product-menus?code=${
          localStorage.getItem("ParentProductCode")
            ? JSON.parse(localStorage.getItem("ParentProductCode"))
            : ParentProductProp?.[0].code
        }`
      )
      .then((data) => {
        setData(data.data.data);
        console.log(data.data.data);
        setLoading(false);
      });
  }, []);
  const handleSelectProduct = (prop, pg = 0) => {
    let temp1 = localStorage.getItem("ParentProduct")
      ? JSON.parse(localStorage.getItem("ParentProduct"))
      : ParentProductProp;
    let _inx = temp1.find((d) => {
      if (d.code == prop?.[0].code) return d;
    });
    console.log(temp1);
    //temp1[0] = {};
    if (_inx == null) {
      temp1.push(prop?.[0]);
      localStorage.setItem("ParentProduct", JSON.stringify(temp1));
    } else {
      let temp2 = [];
      let _flag = 0;
      temp1.forEach((ele) => {
        if (ele.code == prop?.[0].code) {
          if (_flag == 0) temp2.push(ele);
          _flag = 1;
        } else {
          if (_flag == 0) temp2.push(ele);
        }
      });
      localStorage.setItem("ParentProduct", JSON.stringify(temp2));
    }
    if (prop?.[0].hasChildNode === true) {
      setParentProduct(prop);
      //localStorage.setItem("ParentProduct", JSON.stringify(prop));
      localStorage.setItem("ParentProductCode", JSON.stringify(prop?.[0].code));
      // history.push(`/${prop[0].parent}`);
      console.log(`/${prop}`);
      window.location.reload();
    } else {
      if (pg == 1) {
        setParentProduct(prop);
        localStorage.setItem("ParentProduct", JSON.stringify(prop));
        localStorage.setItem(
          "ParentProductCode",
          JSON.stringify(prop?.[0].code)
        );
        // history.push(`/${prop[0].parent}`);
        console.log(`/${prop?.[0].parent}`);
        window.location.reload();
      } else {
        setProductData(prop);
        localStorage.setItem(
          "FinalProductCode",
          JSON.stringify(prop?.[0].code)
        );
        history.push("/product-data");
      }
    }
  };
  const alphabetArray = "0123456789zyxwutsqponmlkjihgfredcbav".split("");
  return (
    <>
      <div className="rest-banner" ref={windowSizeResolution}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        <div className="container-fluid" id={styles.productMobileContainer}>
          <div className={`row min_height ${styles.ContentContainer}`}>
            <div
              className={`${styles.ContentContainer} ${styles.ContentContainerDesc}`}
            >
              {/* <button onClick={() => history.goBack()}>Back</button> */}

              {/* <h5>
                {parentProduct[0].code}
              </h5> */}
              <h1>
                {parentProduct
                  ? parentProduct?.[0].title
                  : ParentProductProp?.[0].title}
              </h1>

              {
                <h5>
                  <FaHandPointRight />
                  {parentData
                    ? parentData.map((element, index) => {
                        return (
                          <>
                            <span
                              class="breadcrubms"
                              onClick={() =>
                                handleSelectProduct(
                                  [
                                    {
                                      title: element?.title,
                                      description: element?.description,
                                      code: element?.code,
                                      hasChildNode: element?.hasChildNode,
                                    },
                                  ],
                                  1
                                )
                              }
                            >
                              {element.title.match(/\d/) !== null
                                ? element.title.slice(2)
                                : element.title}
                            </span>
                            {/* <img src={element.image}>
                      </img> */}
                          </>
                        );
                      })
                    : ParentProductProp?.map((element, index) => {
                        return (
                          <>
                            <span
                              class="breadcrubms"
                              onClick={() =>
                                handleSelectProduct(
                                  [
                                    {
                                      title: element?.title,
                                      description: element?.description,
                                      code: element?.code,
                                      hasChildNode: element?.hasChildNode,
                                    },
                                  ],
                                  1
                                )
                              }
                            >
                              {element.title.match(/\d/) !== null
                                ? element.title.slice(2)
                                : element.title}
                            </span>
                            {/* <img src={element.image}>
                      </img> */}
                          </>
                        );
                      })}
                </h5>
              }
            </div>
            <div className={styles.DictionaryContainer}>
              {/* <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  {historyArray.map((element)=>(
                    <li class="breadcrumb-item"><a href="#">{element.name}</a></li>
                  ))}
                 
                </ol>
              </nav> */}
              {Loading ? (
                <>
                  <h6>Loading...</h6>
                </>
              ) : (
                alphabetArray?.map((alpha, i) => {
                  return (
                    <>
                      {Data &&
                        Data.some((element) =>
                          element.title.startsWith(alpha.toUpperCase())
                        ) && (
                          <>
                            <div className={styles.AlphaContainer} key={i}>
                              <b style={{ visibility: "hidden" }}>
                                {alpha.toUpperCase()}
                              </b>
                              <ul>
                                {Data.sort(function (a, b) {
                                  console.log("sorting", a, b);
                                  var nameA =
                                      (a.title && a.title.toLowerCase()) || "a",
                                    nameB =
                                      (b.title && b.title.toLowerCase()) || "b";
                                  if (nameA < nameB)
                                    //sort string ascending
                                    return -1;
                                  if (nameA > nameB) return 1;
                                  return 0; //default return value (no sorting)
                                })?.map((element, index) => {
                                  return (
                                    element.title.startsWith(
                                      alpha.toUpperCase()
                                    ) && (
                                      <li
                                        key={index}
                                        className={
                                          styles.parentProductImageStyles
                                        }
                                      >
                                        <div
                                          className={
                                            styles.parentProductImageStylesHand
                                          }
                                        >
                                          <FaHandPointRight />
                                        </div>

                                        <div
                                          className={
                                            styles.parentProductImageStylesTitle
                                          }
                                          onClick={() =>
                                            handleSelectProduct([
                                              {
                                                title: element?.title,
                                                description:
                                                  element?.description,
                                                code: element?.code,
                                                hasChildNode:
                                                  element?.hasChildNode,
                                              },
                                            ])
                                          }
                                        >
                                          {" "}
                                          <FaHandPointRight />
                                          {element.title.match(/\d/) !== null
                                            ? element.title.slice(2)
                                            : element.title}
                                        </div>
                                        <div
                                          className={
                                            styles.parentProductImageStylesImg
                                          }
                                        >
                                          {" "}
                                          <img
                                            // style={{
                                            //   width: "20%",
                                            //   marginLeft: "30%",
                                            // }}
                                            alt={
                                              element.title.match(/\d/) !== null
                                                ? element.title.slice(2)
                                                : element.title
                                            }
                                            src={element.image.largeImageURL}
                                            onClick={() =>
                                              handleSelectProduct([
                                                {
                                                  title: element.title,
                                                  description:
                                                    element.description,
                                                  code: element.code,
                                                  hasChildNode:
                                                    element.hasChildNode,
                                                },
                                              ])
                                            }
                                          />
                                        </div>
                                        <hr />
                                      </li>
                                    )
                                  );
                                })}
                              </ul>
                            </div>
                          </>
                        )}
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
        {/* <SideBanner />
        <About /> */}
        {/* <div className="container border-1">
          <div className="row ">
            <div className="col-md-3 border-1 d-flex align-items-end ">
              <SideBanner parentCallback={handleCallback} />
            </div>
            <div className="col-md-8 " style={{ marginTop: "2%" }}>
              {navId === 0 && <Banner />}
              {navId === 1 && <About />}
              {navId === 2 && <Products />}
              {navId === 3 && <Infrastructure />}
              {navId === 4 && <NewsAndEvents />}
              {navId === 5 && <Catalogue />}
            </div>
          </div>
        </div> */}
        {/* <div className="container ">
          <div className="row">
            <div className="col-md-10 " style={{ marginTop: "2%" }}>
              {navId === 0 && <Banner />}
              {navId === 1 && <About />}
              {navId === 2 && <Products />}
              {navId === 3 && <Infrastructure />}
              {navId === 4 && <NewsAndEvents />}
              {navId === 5 && <Catalogue />}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <SideBanner parentCallback={handleCallback} />
            </div>
          </div>
        </div> */}
      </div>
      {/* <br /> */}
    </>
  );
};

export default ParentProductPage;
