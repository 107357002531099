import React, { useEffect, useRef, useState } from "react";
import Header from "../Home/Header";
import Navbar from "../commons/Navbar";
import "../Home/style.css";
import HeaderBox from "../Home/HeaderBox";
import Blogs from "./Blogs";
import BlogsContent from "../assets/Blogs.json";
import { useParams } from "react-router-dom";

const ResourcesPage = ({ navigationId, setNavigationId, setParentProduct }) => {
  const { Blogid } = useParams();

  const BlogItems = BlogsContent.filter((blog) => blog.id === parseInt(Blogid));
  console.log(BlogItems);
  return (
    <>
      <div className={"rest-banner"}>
        <HeaderBox id={navigationId} />
        <Header />
        <Navbar
          setNavigationId={setNavigationId}
          setParentProduct={setParentProduct}
        />
        {/* 
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12 blog-container">
              <Blogs Blogs={BlogItems} />
            </div>
          </div>
        </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3"></div>
            <div className="col-12 col-lg-9">
              <Blogs Blogs={BlogItems} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourcesPage;
